import React from 'react';
import styled from 'styled-components'
import styles from './styles'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Section = styled.section`${styles}`
const Person = ({ name, description, isReverted }) => {
  const images = useStaticQuery(graphql`
  query {
    arnostek: file(relativePath: { eq: "arnost-head.png" }) {
      childImageSharp {
        fluid(
          maxWidth: 600, 
          grayscale: false,
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    dominika: file(relativePath: { eq: "dominika-bitomska.jpg" }) {
      childImageSharp {
        fluid(
          maxWidth: 600, 
          grayscale: false,
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`)

  return (
    <Section>
      <div 
        className={isReverted ? 'profile-photo-area reverted' : 'profile-photo-area'}
      >
        <Img
          className={isReverted ? 'photo reverted' : 'photo'}
          fluid={isReverted ? images.arnostek.childImageSharp.fluid : images.dominika.childImageSharp.fluid} 
          alt="Arnoštek"
        />  
      </div>
      <h2>{name}</h2>
      <p>{description}</p>
    </Section>
  )
}

export default Person