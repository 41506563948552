import { css } from 'styled-components'

export default css`
  margin-bottom: 80px;
  .profile-photo-area {
    width: 100%;
    height: 400px;
    background: white;
    margin-bottom: 40px;
    border-radius: 30% 50% 50% 50%;
    margin-top: -330px;
    border: 1px dashed transparent;
    &.reverted {
      border-radius: 50% 50% 30% 50%;
    }
  }
  h2 {
    font-size: 2.5em;
    color: #62a2c9;
  }
  p {
    color: #2c2c2c;
  }
  .photo {
    max-width: 74%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 12%;
    border-radius: 30% 50% 50% 50%;
    &.reverted {
      border-radius: 50% 50% 30% 50%;
    }
  }

  @media(max-width: 992px) {
    .profile-photo-area {
      height: auto;
      margin-top: 0;
    }
    .photo {
      margin-top: 4%;
      margin-bottom: 4%;
    }
  }
`

