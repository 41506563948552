// @flow
import * as React from 'react'
import styled from 'styled-components'
import styles from './_styles'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import Helmet from 'react-helmet'
import arnostekIMG from '@Images/arnost-head.png'

// layouts
import RootLayout from '@Layouts/root'

// sections
import Person from '@Sections/person'

const Page = styled.section`${styles}`
const ONas = () => (
  <RootLayout>
    <Helmet>
      <title>O nás | Psírehabilitace.cz</title>
      <meta name="description" content="" />
      <meta name="image" content={arnostekIMG} />
      <meta property="og:url" content="https://www.psirehabilitace.cz" />
      <meta property="og:title" content="O nás | Psírehabilitace.cz" />
      <meta property="og:description" content="" />
      <meta property="og:image" content={arnostekIMG} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="Mvdr. Dominika Bitomská" />
      <meta name="twitter:title" content="O nás | Psírehabilitace.cz" />
      <meta name="twitter:description" content="" />
      <meta name="twitter:image" content={arnostekIMG} />
    </Helmet>
    <Page>
      <Container className="rehab-container">
        <Row>
          <Col lg="5">
            <Person 
              name="Dominika"
              description="Jmenuji se Dominika a jsem veterinární lékařka. 
              Vždy jsem toužila dělat kromě klasické medicíny, kterou nás učí 
              na univerzitě, „něco navíc“.  Můj první kontakt s Dornovou metodou 
              byl ještě při studiu na vysoké škole, kdy jsem se zúčastnila kurzu, který pořádala
              Andrea Dunová. Po absolvování navazujících seminářů jsem se terapiím
              Dornovy metody začala věnovat a po splnění dalších podmínek jsem se stala Doporučeným 
              terapeutem. V oblasti manuálních terapií se 
              dále vzdělávám. Miluji hory, cestování (nejlépe cestování do hor), jógu, své nejbližší a samozřejmě zvířata.
              "
            />
          </Col>
          <Col lg="2" />
          <Col lg="5">
            <Person 
              name="Arnošt"
              isReverted
              description="Zdravím vás, mé jméno je Arnošt a jsem junior PR manager. 
              Starám se o klienty, aby se u nás cítili pohodlně. Vždy se 
              snažím přinejmenším o očichání pozadí, pokud je klient 
              otevřený, navazuji bližší tělesný kontakt. Mám rád psy, lidi i 
              děti, vlastně všechno, co můžu olíznout. Můj první kontakt s 
              šéfkou byl při přijímacím pohovoru. Běhal jsem si zablešený 
              a hladový po sídlišti, když mě najednou chytil nějaký 
              pán, nacpal mě do auta a zavezl šéfce do ordinace. Ta okamžitě 
              řekla, že mě bere. Ve volném čase, kterého moc nemám, rád běhám po lese a závodím s lyžaři, cyklisty, běžci. Nepohrdnu spánkem na gauči ani dobrým jídlem.
              "
            />
          </Col>
        </Row>
      </Container>
    </Page>
  </RootLayout>
)

export default ONas
